import { useRouter } from 'next/router';
import React from 'react';

import Layout from '../components/Layout';
import { Seo } from '../components/meta/Seo';
import RenderSections from '../components/sections/render-sections';
import { getClient } from '../lib/sanity';
import { getConfigQuery } from '../queries/config';
import { getSitemapQuery } from '../queries/sitemap';

const NotFoundPage = ({ config, sitemap }) => {
  const router = useRouter();
  const pagePath = router.asPath;

  return (
    <Layout
      config={config}
      sitemap={sitemap}
      reloadPreview={() => {}}
      pagePath={pagePath}
      sitemapItem={{}}
    >
      <Seo
        page={{
          title: config.notFoundPage?.title,
        }}
        config={config}
        sitemap={sitemap}
        sitemapItem={{ path: pagePath, disallowRobots: true }}
      />

      {config.notFoundPage?.content && (
        <RenderSections sections={config.notFoundPage?.content} />
      )}
    </Layout>
  );
};

export default NotFoundPage;

/**
 * Get static props:
 * - sitemap for resolving all links
 */

export const getStaticProps = async () => {
  // fetch sitemap
  const flatSitemap = await getClient().fetch(getSitemapQuery());
  const sitemap = flatSitemap
    .flat()
    .filter(Boolean)
    .filter((item) => Boolean(item.path));

  if (!sitemap.filter(Boolean).length) return { notFound: true };

  const config = await getClient().fetch(
    getConfigQuery(process.env.NEXT_PUBLIC_SCOPE),
  );

  const props = {
    config,
    sitemap,
  };

  return { props, revalidate: 100 };
};
